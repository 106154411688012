import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  connect() {
    // console.log("connect::toggle-controller");
  }

  toggle() {
    this.elementTarget.classList.toggle("active");
  }

  open() {
    this.elementTarget.classList.add("active");
  }

  close() {
    this.elementTarget.classList.remove("active");
  }
}
