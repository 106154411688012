import { Controller } from "stimulus";

export default class extends Controller {
  // static targets = ["menu", "menuNav", "shopLink"];
  static targets = ["menu", "menuNav"];

  connect() {
    // console.log("connect::menu-controller");

    if (window.innerWidth < 1280) {
      this.menuReferenceDistance = this.menuTarget.offsetHeight * 1.33;
      this.menuConstrained = document.querySelector(".menu-constrained");

      const pageWrapper = document.querySelector(".page-wrapper");
      pageWrapper.style.marginTop = `${this.menuTarget.offsetHeight}px`;
    }
  }

  open() {
    if (this.menuTarget) this.menuTarget.classList.add("active");
    if (this.menuConstrained) this.menuConstrained.classList.add("active");
    if (this.menuNavTarget) this.menuNavTarget.classList.add("active");
  }

  close() {
    if (this.menuTarget) this.menuTarget.classList.remove("active");
    if (this.menuConstrained) this.menuConstrained.classList.remove("active");
    if (this.menuNavTarget) this.menuNavTarget.classList.remove("active");
  }

  onScroll() {
    if (window.innerWidth < 1280) {
      const that = this;

      if (window.scrollY <= that.menuReferenceDistance + 10) {
        if (that.menuTarget) that.menuTarget.classList.remove("step-back");
        if (that.menuNavTarget) that.menuNavTarget.classList.remove("sticky");
        if (that.menuConstrained) that.menuConstrained.classList.remove("visible");
      }
      else if (window.scrollY > that.menuReferenceDistance + 10) {

        if (that.menuNavTarget) that.menuNavTarget.classList.add("sticky");
        if (that.menuConstrained) that.menuConstrained.classList.add("visible");
      }

      if (window.scrollY <= that.menuReferenceDistance) {
        if (that.menuTarget) that.menuTarget.classList.remove("constrained-active");
        if (that.menuConstrained) that.menuConstrained.classList.remove("ready");
        // that.shopLinkTarget.classList.remove("visible");
      }
      else if (window.scrollY > that.menuReferenceDistance) {
        if (that.menuTarget) that.menuTarget.classList.add("constrained-active");
        if (that.menuConstrained) that.menuConstrained.classList.add("ready");
        // that.shopLinkTarget.classList.add("visible");
      }
    }
  }
}
