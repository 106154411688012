import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider"];

  connect() {
    // console.log("connect::slider-controller");

    let breakpointWidth = this.sliderTarget.getAttribute("data-breakpoint-on");

    if (breakpointWidth) {
      breakpointWidth = parseInt(breakpointWidth);
    }
    else {
      breakpointWidth = 0;
    }

    if (window.innerWidth <= breakpointWidth) {
      this.sliderTarget.classList.add("swiper-container");
      this.sliderTarget.querySelector(".slider--wrapper").classList.add("swiper-wrapper");

      let slides = this.sliderTarget.querySelectorAll(".slider--slide");

      for (let slide of slides) {
        slide.classList.add("swiper-slide");
      }
    }

    if (breakpointWidth == 0 || window.innerWidth <= breakpointWidth) {
      this.setup();
    }
  }

  setup() {
    const slider = this.sliderTarget;
    const config = JSON.parse(slider.getAttribute("data-config"));

    let swiper;

    new imagesLoaded(slider, { background: true }, function() {
      swiper = new Swiper(slider, config);
    });
  }
}
