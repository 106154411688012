export const initApp = async () => {
  if (!window.Turbo) {
    const Rails = require("@rails/ujs");
    Rails.start();

    const Turbo = require("@hotwired/turbo-rails");

    window.Turbo = Turbo;
  }

  return window.Turbo;
}

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

window.debounce = function(func, wait = 100) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("../controllers/front", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

import LocomotiveScroll from "locomotive-scroll";

// JS
import "../js/youtube_api_loader.js";

// styles
import "../styles/normalize.css";
import "../styles/front/locomotive_scroll.css";
import "../styles/shared.scss";
import "../styles/front/application.scss";
import "../styles/shared/front/menu.scss";
import "../styles/shared/front/langs_select.scss";
import "../styles/shared/front/fade_block.scss";
import "../styles/shared/front/contact_form.scss";
import "../styles/shared/front/product_card.scss";
import "../styles/shared/front/variety_card.scss";
import "../styles/shared/front/publication_card.scss";
import "../styles/shared/front/calendar.scss";
// import "../styles/shared/front/shop_button.scss";
import "../styles/shared/front/commerce_cards.scss";
import "../styles/shared/front/share_button.scss";
import "../styles/shared/front/video_viewer.scss";
import "../styles/shared/front/footer.scss";
import "../styles/shared/front/hooks.scss";
import "../styles/front/pages/home.scss";
import "../styles/front/pages/company.scss";
import "../styles/front/pages/environment.scss";
import "../styles/front/pages/varieties.scss";
import "../styles/front/pages/products.scss";
import "../styles/front/pages/contact.scss";
import "../styles/front/pages/publications.scss";

let locomotiveScroll;
let prevWindowWidth = window.innerWidth;
let menu;
let menuConstrained;
let menuReferenceDistance;
// let shopLink;
let isScrolling;

function locomotiveAsyncInit() {
  locomotiveScroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    smoothMobile: false,
    getSpeed: false,
		getDirection: false,
    firefoxMultiplier: 100,
    lerp: 0.75,
    inertia: 0.8,
    touchMultiplier: 10,
    scrollFromAnywhere: false,
    reloadOnContextChange: true,
    resetNativeScroll: true,
    tablet: {
      breakpoint: 1280,
      smooth: false,
      getSpeed: false,
		  getDirection: false,
      inertia: 0,
    },
    smartphone: {
      smooth: false,
      getSpeed: false,
		  getDirection: false,
      inertia: 0,
    }
  });

  locomotiveScroll.on("scroll", (obj) => {
    console.log('scroll...');
    menuConstrained.style.pointerEvents = "none";
    window.clearTimeout(isScrolling);

    let inners = window.innerWidth < 1280 ?
      document.querySelectorAll("#map, .menu-mobile, .modal, .video-viewer--viewer.-as-modal")
      :
      document.querySelectorAll("#map, .menu-mobile, .modal, .video-viewer--viewer.-as-modal");

    for (let inner of inners) {
      inner.onmouseover = function() {
        locomotiveScroll.stop();
      };

      inner.onmouseout = function() {
        locomotiveScroll.start();
      };
    }

    if (obj.scroll.y <= menuReferenceDistance) {
      menuConstrained.classList.remove("visible");
      // shopLink.classList.remove("visible");
    }
    else if (obj.scroll.y > menuReferenceDistance + 10) {
      menuConstrained.classList.add("visible");
      // shopLink.classList.add("visible");
    }

    isScrolling = setTimeout(function() {
      menuConstrained.style.pointerEvents = "initial";
    }, 10);
  });
}

document.addEventListener("DOMContentLoaded", function() {
  initApp();

  localStorage.setItem('ww', window.innerWidth);
  window.addEventListener('resize', debounce(function() {
    let prevww = parseInt(localStorage.getItem('ww'));
    let currww = window.innerWidth;

    if (
          (prevww > 575) && (currww <= 575)
          ||
          (prevww < 575 && currww >= 575)
          ||
          (prevww < 768 && currww >= 768)
          ||
          (prevww < 991 && currww >= 991)
          ||
          (prevww < 1024 && currww >= 1024)
          ||
          (prevww < 1280 && currww >= 1280)
      ) {
        location.reload();
      }

    localStorage.setItem('ww', currww);
  }), 300);

  window.setTimeout(() => {
    document.body.classList.remove('loading')
  }, 5000)
});

document.addEventListener('turbo:before-render', () => {
  loadingStart()
})

document.addEventListener('turbo:load', () => {
  loadingEnd()
})

document.addEventListener("turbo:load", function () {
  if (window.innerWidth >= 1280) {
    menu = document.querySelector(".menu");
    menuConstrained = document.querySelector(".menu-constrained");
    menuReferenceDistance = menu.offsetHeight * 1.33;
    // shopLink = document.querySelector("#shopButton");

    locomotiveAsyncInit();
  }

  loadingEnd();
});


function loadingStart() {
  document.body.classList.add('loading')
}

function loadingEnd() {
  new imagesLoaded(document.body, { background: true }, () => {
    if (locomotiveScroll) locomotiveScroll.update();
    document.body.classList.remove('loading')
  })
}
