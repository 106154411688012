import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "notice"];

  connect() {
    // console.log("connect::clipboard-controller");
  }

  copy() {
    let copyText = this.contentTarget;

    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    navigator.clipboard.writeText(copyText.value);

    this.notice();
  }

  notice() {
    const that = this;

    that.noticeTarget.classList.add("visible");

    that.noticeTimeout = window.setTimeout(function() {
      that.noticeTarget.classList.remove("visible");
    }, 700);
  }

  clearNotice() {
    if (window.innerWidth > 1024) {
      window.clearTimeout(this.noticeTimeout);
      this.noticeTarget.classList.remove("visible");
    }
  }
}
