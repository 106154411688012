import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // console.log("connect::logout-redirect-controller");

    location.reload();
    window.location.href = "/noticias";
  }
}
